import { Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";

const Appmain = React.lazy(() => import("./component/partials/AppMain"));
const PublicRoute = React.lazy(() => import("./authentication/PublicRoutes"));
const PrivateRoute = React.lazy(() => import("./authentication/PrivateRoutes"));
const Login = React.lazy(() => import("./authentication/Login"));
const EmailVerification = React.lazy(() =>
    import("./authentication/EmailVerification")
);
const Logout = React.lazy(() => import("./authentication/Logout"));
const NotFound = React.lazy(() => import("./component/NotFound"));

const App = () => {
    return (
        <Suspense fallback={""}>
            <Routes>
                <Route
                    path="/"
                    element={
                        <PublicRoute>
                            <Login />
                        </PublicRoute>
                    }
                />

                <Route
                    path="/emailverification"
                    element={
                        <PublicRoute>
                            <EmailVerification />
                        </PublicRoute>
                    }
                />

                <Route path="/logout" element={<Logout />} />

                <Route
                    path="/dashboard/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/user/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/affiliate/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/analytic/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/kyc/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/series/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/match/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/contest/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/team/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/player/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/selection/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/point/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/payment/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/tds/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/marketing/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/leaderboard/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/promo/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/notification/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/contact/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/feedback/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/reward/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/app/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/admin/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/code/*"
                    element={
                        <PrivateRoute>
                            <Appmain />
                        </PrivateRoute>
                    }
                />

                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    );
};

export default App;